import { addHours, endOfDay, startOfDay } from "date-fns";
import { getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useCallback, useMemo } from "react";
import { useUser } from "@/auth/useUser";
import { useMedspaTimezoneQuery } from "@/graphql/queries/medspaTimezone.graphql.types";

export default function useMedspaTimezone(medspaId?: string) {
  const { medspa } = useUser();

  const medspaToUse = medspaId || medspa;

  const { data } = useMedspaTimezoneQuery({
    variables: {
      id: medspaToUse,
    },
    skip: !medspaToUse,
  });

  const medspaTimezone = data?.medspaByPk.timezone || "";

  const utcToMedspaZonedTime = useCallback(
    (date: string | number | Date) =>
      typeof date !== "undefined" ? utcToZonedTime(date, medspaTimezone) : date,
    [medspaTimezone]
  );

  const medspaZonedTimeToUtc = useCallback(
    (date: string | number | Date) =>
      typeof date !== "undefined" ? zonedTimeToUtc(date, medspaTimezone) : date,
    [medspaTimezone]
  );

  const utcToZonedTimeIso = useCallback(
    (date: string | number | Date) => utcToMedspaZonedTime(date)?.toISOString(),
    [utcToMedspaZonedTime]
  );

  const zonedTimeToUtcIso = useCallback(
    (date: string | number | Date) => medspaZonedTimeToUtc(date)?.toISOString(),
    [medspaZonedTimeToUtc]
  );

  const getMedspaCurrentTime = useCallback(() => {
    return utcToMedspaZonedTime(new Date());
  }, [utcToMedspaZonedTime]);

  const addTimezoneOffset = useCallback(
    (date: Date) => {
      if (!medspaTimezone) return new Date();

      const localTimeTZOffset = (new Date().getTimezoneOffset() / 60) * -1;

      const medspaTZOffset =
        -1 *
        Math.floor((getTimezoneOffset(medspaTimezone) / (1000 * 60 * 60)) % 24);

      // Calculate offset to add/substract to difference in hours depending on the
      // local machine timezone offset and the medspa timezone offset
      const offset = medspaTZOffset + localTimeTZOffset;

      return addHours(date, offset);
    },
    [medspaTimezone]
  );

  const getMedspaStartOfDay = useCallback(
    (date?: Date) => addTimezoneOffset(startOfDay(date ?? new Date())),
    [addTimezoneOffset]
  );

  const getMedspaEndOfDay = useCallback(
    (date?: Date) => addTimezoneOffset(endOfDay(date ?? new Date())),
    [addTimezoneOffset]
  );

  const medspaCurrentTime = useMemo(
    () => getMedspaCurrentTime(),
    [getMedspaCurrentTime]
  );

  return {
    medspaCurrentTime,
    addTimezoneOffset,
    utcToMedspaZonedTime,
    medspaZonedTimeToUtc,
    utcToZonedTimeIso,
    zonedTimeToUtcIso,
    medspaReadableTimezone: medspaTimezone.replaceAll("_", " ") || "",
    getMedspaStartOfDay,
    getMedspaEndOfDay,
    getMedspaCurrentTime,
    medspaTimezoneLoading: !data,
  };
}

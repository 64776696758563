import { gql } from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

export type TelehealthFieldsFragment = {
  __typename?: "visit";
  id: string;
  isVisitTelehealth?: boolean | null;
  telehealthVisitDetails?: {
    __typename?: "TelehealthVisitDetails";
    visitType: string;
    videoLink?: string | null;
    visitDetails?: string | null;
  } | null;
};

export type TelehealthIconFieldsFragment = {
  __typename?: "visit";
  id: string;
  isVisitTelehealth?: boolean | null;
  telehealthVisitDetails?: {
    __typename?: "TelehealthVisitDetails";
    visitType: string;
    videoLink?: string | null;
  } | null;
};

export const TelehealthFieldsFragmentDoc = gql`
  fragment TelehealthFields on visit {
    id
    isVisitTelehealth
    telehealthVisitDetails {
      visitType
      videoLink
      visitDetails
    }
  }
`;
export const TelehealthIconFieldsFragmentDoc = gql`
  fragment TelehealthIconFields on visit {
    id
    isVisitTelehealth
    telehealthVisitDetails {
      visitType
      videoLink
    }
  }
`;

import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { differenceInMinutes, format } from "date-fns";
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PinpaperCrossIcon, UserWarningIcon } from "@/components/common/icons";
import TooltipCrownIcon from "@/components/common/icons/tooltipCrownIcon";
import TooltipWalletIcon from "@/components/common/icons/tooltipWalletIcon";
import { getColorForStatus } from "@/components/serviceFlow/events/commons";
import { TIME_FORMATS } from "@/config";
import useFeatureFlags from "@/hooks/common/useFeatureFlags";
import { VisitStatus } from "@/types";
import {
  MoxieEventContentArg,
  MoxieEventImpl,
  VisitExtendedEventProps,
} from "@/types/calendarEventType";
import { getTelehealthIcon } from "@/utils/telehealth";
import { GfeForm } from "@/utils/visit";
import VisitDropdown from "../visitDetails/visitDropdown";
import { EventTileChips } from "./eventTileChips";

export const VisitCustomTile = ({
  event,
}: MoxieEventContentArg<VisitExtendedEventProps>) => {
  const { telehealthServicesV1Enabled } = useFeatureFlags();
  const {
    id,
    title,
    start,
    end,
    extendedProps: {
      view,
      status,
      reviewStatus,
      formSubmissions,
      services,
      showDropdown,
      isNewClient,
      gfeNeeded,
      hasFormsToFill,
      hasWalletItems,
      clientMembershipsList,
      gfeGeneralStatusOld,
      gfeGeneralStatus,
      eventColorPalette,
      clickable = true,
      isVisitTelehealth,
      telehealthVisitDetails,
    },
  } = event;

  const color = getColorForStatus(status);

  const theme = useTheme();

  const isMobile = useMediaQuery(
    `(max-width: ${theme.breakpoints.values["ipad-hor"]}px)`
  );

  const rootStackRef = useRef<HTMLDivElement>(null);

  const [rootStackSizes, setRootStackSizes] = useState({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    if (rootStackRef.current) {
      const { width, height } = rootStackRef.current.getBoundingClientRect();

      if (width != rootStackSizes.width || height != rootStackSizes.height) {
        setRootStackSizes({ width, height });
      }
    }
  }, [setRootStackSizes, rootStackSizes]);

  const isNarrowStack = useMemo(
    () => rootStackSizes.width < 200,
    [rootStackSizes]
  );

  const visitTime =
    start && end
      ? `${format(start, TIME_FORMATS.COMPACT)} - ${format(end, TIME_FORMATS.COMPACT)}`
      : "";

  const duration = start && end ? differenceInMinutes(end, start) : 0;

  const { showIcons, showChip, ...visitRules } = getVisitTileRules(
    duration,
    isMobile
  );
  const showWalletAndNewClientIcons =
    showIcons && (duration > 30 || (isMobile && duration >= 45));

  const iconColor =
    status === VisitStatus.COMPLETED
      ? "currentColor"
      : eventColorPalette?.[80] ?? color;

  const renderIcons = useCallback(
    () => (
      <Stack
        direction={isNarrowStack && duration >= 60 ? "column" : "row"}
        gap={1}
        justifyContent="flex-end"
      >
        {showIcons && (
          <Stack
            direction="row"
            justifyContent={
              !isMobile && !isNarrowStack && duration >= 45
                ? "space-between"
                : "flex-start"
            }
            flex={1}
          >
            {showWalletAndNewClientIcons && (
              <Stack direction="row" alignItems="center" gap={0.5}>
                {hasWalletItems && <TooltipWalletIcon color={iconColor} />}
                {!!clientMembershipsList && (
                  <TooltipCrownIcon
                    tooltipText={clientMembershipsList}
                    color={iconColor}
                  />
                )}
              </Stack>
            )}
            {showIcons && (
              <VisitIcons
                color={iconColor}
                gfeRequired={gfeNeeded}
                newClient={true || isNewClient}
                formsToFillIn={true || hasFormsToFill}
              />
            )}
          </Stack>
        )}
        {showChip && (
          <EventTileChips
            status={status}
            view={view}
            gfeGeneralStatus={gfeGeneralStatus}
            gfeGeneralStatusOld={gfeGeneralStatusOld}
            duration={duration}
          />
        )}
      </Stack>
    ),
    [
      isMobile,
      isNarrowStack,
      showWalletAndNewClientIcons,
      showIcons,
      hasWalletItems,
      clientMembershipsList,
      showChip,
      gfeNeeded,
      isNewClient,
      hasFormsToFill,
      status,
      view,
      gfeGeneralStatusOld,
      gfeGeneralStatus,
      duration,
      iconColor,
    ]
  );

  if (duration < 30) {
    return <ShortVisitTile event={event} />;
  }

  const titleServicesSameLine =
    !isNarrowStack && duration <= 45 && duration > 35;

  const showTime =
    visitRules.showTime &&
    ((!isMobile && !isNarrowStack) ||
      (isNarrowStack ? duration >= 70 : isMobile && duration >= 45));

  const telehealthIcon = getTelehealthIcon(
    telehealthVisitDetails,
    "20px",
    iconColor
  );

  const showTelehealthIcon = telehealthServicesV1Enabled && isVisitTelehealth;

  return (
    <Stack
      ref={rootStackRef}
      justifyContent={
        isNarrowStack && duration <= 45 ? "center" : "space-between"
      }
      sx={{
        width: "100%",
        height: "100%",
        cursor: clickable ? "pointer" : "default",
        color: "currentcolor",
        textDecoration: "inherit",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        gap={0.5}
      >
        <Typography
          variant="labelTiny"
          noWrap
          sx={{
            color: "currentcolor",
            textDecoration: "inherit",
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
            display: "flex",
            mt: duration <= 30 && !isNarrowStack ? -0.35 : 0,
            flexDirection: titleServicesSameLine ? "row" : "column",
          }}
        >
          <Typography variant="labelTiny" component="span">
            {title}
          </Typography>
          {titleServicesSameLine && (
            <Typography component="span" sx={{ width: "8px" }} />
          )}
          <Stack direction="row" gap={0.5}>
            {showTelehealthIcon && telehealthIcon}
            <Typography variant="labelTiny" component="span">
              {services}
            </Typography>
          </Stack>
        </Typography>

        {(showDropdown || (showIcons && duration < 40)) && (
          <Stack
            direction="row"
            gap={1}
            mt={duration <= 30 && isNarrowStack ? 0.25 : 0}
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              flexShrink: 1000,
              flexGrow: 0,
              overflow: "hidden",
              flexWrap: "noWrap",
              minWidth: isNarrowStack ? "38px" : "74px",
            }}
          >
            {duration < 40 && (
              <Box
                sx={{
                  mr: !isNarrowStack ? 4 : 0,
                }}
              >
                {renderIcons()}
              </Box>
            )}
            {showDropdown ? (
              <Box mt={-0.5} position="absolute" sx={{ top: 0, right: 0 }}>
                <VisitDropdown
                  visitId={id}
                  visitStatus={status}
                  reviewStatus={reviewStatus}
                  formSubmissions={formSubmissions as GfeForm[]}
                />
              </Box>
            ) : null}
          </Stack>
        )}
      </Stack>
      <Stack mt={showTime && duration <= 45 ? -1.5 : 0}>
        {showTime && (
          <Typography variant="labelMicro" noWrap>
            {visitTime}
          </Typography>
        )}
        {duration >= 40 && renderIcons()}
      </Stack>
    </Stack>
  );
};

const ShortVisitTile = ({
  event,
}: {
  event: MoxieEventImpl<VisitExtendedEventProps>;
}) => {
  const {
    id,
    title,
    start,
    end,
    extendedProps: {
      view,
      status,
      reviewStatus,
      formSubmissions,
      services,
      showDropdown,
      hasWalletItems,
      clientMembershipsList,
      gfeGeneralStatusOld,
      gfeGeneralStatus,
      clickable = true,
    },
  } = event;

  const color = getColorForStatus(status);

  const theme = useTheme();

  const duration = differenceInMinutes(end, start);

  const isMobile = useMediaQuery(
    `(max-width: ${theme.breakpoints.values["ipad-hor"]}px)`
  );

  const rootStackRef = useRef<HTMLDivElement>(null);

  const [rootStackSizes, setRootStackSizes] = useState({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    if (rootStackRef.current) {
      const { width, height } = rootStackRef.current.getBoundingClientRect();

      if (width != rootStackSizes.width || height != rootStackSizes.height) {
        setRootStackSizes({ width, height });
      }
    }
  }, [setRootStackSizes, rootStackSizes]);

  const isNarrowStack = useMemo(
    () => rootStackSizes.width < 200,
    [rootStackSizes]
  );

  const showIcons = hasWalletItems || !!clientMembershipsList;

  return (
    <Stack
      ref={rootStackRef}
      direction="row"
      justifyContent="space-between"
      mt={"-1px"}
      gap={0.5}
      sx={{
        cursor: clickable ? "pointer" : "default",
        minWidth: 0,
        color: "currentcolor",
        position: "relative",
        textDecoration: "inherit",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Typography
        variant="labelTiny"
        noWrap
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 0.15,
          mr: isNarrowStack ? 4 : 0,
          color: "currentcolor",
          textDecoration: "inherit",
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: "auto",
        }}
      >
        {title || ""}
        {services && ` • ${services}`}
      </Typography>
      {((!isNarrowStack && showIcons) || showDropdown) && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          gap={0.5}
          alignItems="center"
          sx={{
            flexGrow: 0,
            flexShrink: 1000,
            overflow: "hidden",
            flexWrap: "noWrap",
            minWidth: isNarrowStack ? "24px" : "74px",
          }}
        >
          {!isNarrowStack && (
            <Stack
              direction="row"
              sx={{
                gap: 1,
                mt: 0.15,
                flex: 1,
                mr: 4,
              }}
            >
              {!isMobile && (hasWalletItems || !!clientMembershipsList) && (
                <Stack direction="row" gap={1} height={16} overflow="hidden">
                  {hasWalletItems && (
                    <Box mt={-0.25}>
                      <TooltipWalletIcon color={color} />
                    </Box>
                  )}
                  {!!clientMembershipsList && (
                    <Box mt={-0.25}>
                      <TooltipCrownIcon
                        tooltipText={clientMembershipsList}
                        color={color}
                      />
                    </Box>
                  )}
                </Stack>
              )}
              <EventTileChips
                status={status}
                view={view}
                gfeGeneralStatus={gfeGeneralStatus}
                gfeGeneralStatusOld={gfeGeneralStatusOld}
                duration={duration}
              />
            </Stack>
          )}
          {showDropdown && (
            <Box
              sx={{
                mt: duration > 15 ? -1 : -1.5,
                flex: 0,
                position: "absolute",
                top: "15%",
                right: 0,
              }}
            >
              <VisitDropdown
                visitId={id}
                visitStatus={status}
                reviewStatus={reviewStatus}
                formSubmissions={formSubmissions as GfeForm[]}
              />
            </Box>
          )}
        </Stack>
      )}
    </Stack>
  );
};

function VisitIcons({
  gfeRequired,
  newClient,
  formsToFillIn,
  color,
}: {
  gfeRequired?: boolean;
  newClient?: boolean;
  formsToFillIn?: boolean;
  color: string;
}) {
  if (!gfeRequired && !newClient && !formsToFillIn) return null;

  return (
    <Stack direction="row" gap={0.5} alignItems="center">
      {newClient && <UserWarningIcon color={color} size="16px" />}
      {formsToFillIn && <PinpaperCrossIcon color={color} size="16px" />}
    </Stack>
  );
}

type VisitRules = {
  showIcons: boolean;
  showChip: boolean;
  showTime: boolean;
};

const getVisitTileRules = (duration: number, isMobile = false): VisitRules => {
  if (duration >= 45) {
    return {
      showChip: true,
      showIcons: true,
      showTime: true,
    };
  }

  if (duration >= 30 && duration < 45) {
    return {
      showTime: false,
      showChip: !isMobile,
      showIcons: !isMobile,
    };
  }

  if (duration < 30) {
    return {
      showTime: false,
      showChip: !isMobile,
      showIcons: !isMobile,
    };
  }
};

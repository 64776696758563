import { isBefore } from "date-fns";
import toast from "react-hot-toast";
import {
  OpenConfirmOptions,
  useConfirm,
} from "@/components/providers/confirmProvider";
import { useChargeFeeAdditionalInfoLazyQuery } from "@/graphql/queries/visit/visitDropdown.graphql.types";
import { hasDjangoMutationError } from "@/utils/djangoMutationError";
import useErrorLogger from "@/utils/useErrorLogger";
import useChargeNoShowFee from "./useChargeNoShowFee";
import { FEE_CHANGED_MODAL_CONFIG } from "./useMarkAsNoShow";

export default function useChargeLateCancellationFee(visitId: string) {
  const logError = useErrorLogger();
  const { getConfirmWithCheckbox, getConfirm } = useConfirm();
  const chargeNoShowFee = useChargeNoShowFee(visitId);
  const [getLateCancellationFeeInfo] = useChargeFeeAdditionalInfoLazyQuery({
    variables: {
      visitId,
    },
  });

  const handleChargeLateCancellationFee = async (
    modalOptions: Partial<OpenConfirmOptions> = {}
  ) => {
    const { data } = await getLateCancellationFeeInfo();
    const hasCardsOnFile =
      data.visitByPk.client.stripeData.stripeCardsList.length > 0;
    const collectCardOnBooking =
      data.visitByPk.medspa.configuration.collectCardOnBooking;
    const noShowFeeValue = data.visitByPk.medspa.configuration.noShowFeeValue;
    const visitCreatedDate = data.visitByPk.created;
    const lastEditedNoShowFeeAt =
      data.visitByPk.medspa.configuration.additionalInfo.lastEditedNoShowFeeAt;

    if (!collectCardOnBooking) {
      return;
    }

    if (!hasCardsOnFile) {
      toast.error("Unable to charge no-show fee - client has no card on file.");
      return;
    }

    const hasFeeChanged = isBefore(
      new Date(visitCreatedDate),
      new Date(lastEditedNoShowFeeAt)
    );

    if (hasFeeChanged) {
      const ok = await getConfirm({
        ...FEE_CHANGED_MODAL_CONFIG,
        description: FEE_CHANGED_MODAL_CONFIG.description.replaceAll(
          "no-show",
          "late cancellation"
        ),
      });

      if (!ok) {
        return;
      }
    }

    const { confirmed, checkboxValue: sendCommunication } =
      await getConfirmWithCheckbox(
        {
          title: "Confirm charging late cancellation fee?",
          description: `Charge a $${noShowFeeValue} late cancellation fee to the credit card on file?`,
          confirmButtonText: "Yes",
          discardButtonText: "No",
          ...modalOptions,
        },
        {
          defaultValue: true,
          label:
            "Send e-mail and text to the client with this information and receipt",
        }
      );
    if (!confirmed) {
      return;
    }

    const id = toast.loading("Preparing to charge late cancellation fee...");
    try {
      await chargeNoShowFee(sendCommunication);
      toast.success("Entering payment flow to charge late cancellation fee", {
        id,
      });
    } catch (errors) {
      toast.error(
        hasDjangoMutationError(errors)
          ? errors.message
          : "Unable to charge late cancellation fee. Please contact support team.",
        { id }
      );
      logError(errors);
    }
  };

  return handleChargeLateCancellationFee;
}

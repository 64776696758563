import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { hasRole, useUser } from "@/auth/useUser";
import DropdownMenu, {
  DropdownMenuItems,
} from "@/components/common/dropdownMenu/dropdownMenu";
import {
  MenuHorizontalIcon,
  WarningCircleIcon,
} from "@/components/common/icons";
import { ERROR_PRIMARY, PRIMARY } from "@/config/mui/colorPalette";
import { useSetVisitStatusMutation } from "@/graphql/mutations/setVisitStatus.graphql.types";
import { useChargeFeeAdditionalInfoQuery } from "@/graphql/queries/visit/visitDropdown.graphql.types";
import useFeatureFlags from "@/hooks/common/useFeatureFlags";
import useCancelAppointment, {
  checkIfItIsLateCancellation,
} from "@/hooks/serviceFlow/visitDropdown/useCancelAppointment";
import useChargeLateCancellationFee from "@/hooks/serviceFlow/visitDropdown/useChargeLateCancellationFee";
import { useChartReviewActions } from "@/hooks/serviceFlow/visitDropdown/useChartReviewActions";
import useCurrentVisitDropdownFragment from "@/hooks/serviceFlow/visitDropdown/useCurrentVisitDropdownFragment";
import useMarkAsNoShow from "@/hooks/serviceFlow/visitDropdown/useMarkAsNoShow";
import { useSelfReviewGfeItem } from "@/hooks/serviceFlow/visitDropdown/useSelfReviewGfeItem";
import { useSendChartToReviewItem } from "@/hooks/serviceFlow/visitDropdown/useSendChartToReviewItem";
import { useSendGfeToReviewItem } from "@/hooks/serviceFlow/visitDropdown/useSendGfeToReviewItem";
import { useStartReviewDropdownItem } from "@/hooks/serviceFlow/visitDropdown/useStartReviewDropdownItem";
import { useIsEligibleToRequestScripts } from "@/hooks/user/useIsEligibleToRequestScripts";
import { useIsEligibleWeightLossPharmacies } from "@/hooks/user/useIsEligibleWeightLossPharmacies";
import useCompleteVisit from "@/hooks/visits/useCompleteVisit";
import {
  FRONT_DESK,
  InvoiceStatus,
  PROVIDER,
  ReviewStatus,
  VisitStatus,
} from "@/types";
import useErrorLogger from "@/utils/useErrorLogger";
import { GfeForm, hasSomeGfesFilled } from "@/utils/visit";

type VisitDropdownProps = {
  visitId: string;
  formSubmissions: GfeForm[];
  reviewStatus: ReviewStatus;
  visitStatus: VisitStatus;
};

// TODO: Adjust formSubmissions type
function VisitDropdown({
  visitId,
  formSubmissions = [],
  reviewStatus,
  visitStatus,
}: VisitDropdownProps) {
  const { newGfeFlowV1, chartsOverdueV1Enabled, adverseReactionsV1Enabled } =
    useFeatureFlags();
  const isEligibleToRequestScripts = useIsEligibleToRequestScripts();
  const eligibleWeightLossPharmacies = useIsEligibleWeightLossPharmacies();
  const logError = useErrorLogger();
  const { medspa, user } = useUser();
  const {
    query: { slug, tab },
    push,
  } = useRouter();

  const { data } = useCurrentVisitDropdownFragment(visitId);
  const { data: chargeFeeData } = useChargeFeeAdditionalInfoQuery({
    variables: {
      visitId,
    },
    skip: !visitId,
  });

  const [setVisitStatus] = useSetVisitStatusMutation();

  const cancelAppointment = useCancelAppointment(visitId);
  const markVisitAsNoShow = useMarkAsNoShow(visitId);
  const chargeLateCancellationFee = useChargeLateCancellationFee(visitId);
  const completeVisit = useCompleteVisit({
    id: visitId,
    status: visitStatus,
    invoice: data.invoice,
  });

  const handleChangeVisitStatus = async (status: VisitStatus) => {
    try {
      await setVisitStatus({
        variables: {
          id: visitId || (slug as string),
          status,
        },
      });
    } catch (errors) {
      logError(errors);
    }
  };
  const hasCardsOnFile =
    chargeFeeData?.visitByPk.client.stripeData.stripeCardsList.length > 0;
  const collectCardOnBooking =
    chargeFeeData?.visitByPk.medspa.configuration.collectCardOnBooking;
  const isAlreadyInPaymentFlow =
    data?.invoice && data.invoice.status !== InvoiceStatus.DRAFT;
  const clientId = data?.client.id;

  const isEditable = [VisitStatus.SCHEDULED, VisitStatus.CONFIRMED].includes(
    visitStatus
  );

  const { selfApproveGfeItem, selfDeclineGfeItem } = useSelfReviewGfeItem(
    visitId,
    formSubmissions
  );
  const sendGfeToMdItem = useSendGfeToReviewItem(formSubmissions);
  const sendChartToMdItem = useSendChartToReviewItem(
    visitStatus,
    reviewStatus,
    visitId,
    "Mark chart as completed",
    data?.invoice?.id
  );

  const isChartSigned = data?.lastSignature?.length > 0;

  const { actions: chartDropdownItems, dialogComponent } =
    useChartReviewActions(
      visitId,
      visitStatus,
      reviewStatus,
      isChartSigned,
      data?.invoice?.id
    );

  const hasGfesFilled = hasSomeGfesFilled(formSubmissions);

  const pendingReviewId = data?.client?.pendingGfeReview?.[0]?.id;
  const lastReviewId = data?.client?.lastGfeReview?.[0]?.id;
  const backUrl = `/${medspa}/visits/${visitId}/${tab ? tab : "overview"}`;

  const startReviewItem = useStartReviewDropdownItem(
    pendingReviewId,
    lastReviewId,
    clientId,
    backUrl
  );

  const isLateCancellation = checkIfItIsLateCancellation(
    data?.appointment?.startTime,
    chargeFeeData
  );

  const getCancelAppointmentLabel = () => {
    return isLateCancellation
      ? "Late cancel appointment"
      : "Cancel appointment";
  };

  const items: DropdownMenuItems = [
    {
      component: "Go to checkout",
      onClick: completeVisit,
      disabled: !(isEditable && hasRole(user, [PROVIDER])),
    },
    {
      component: "Mark as scheduled",
      onClick: () => handleChangeVisitStatus(VisitStatus.SCHEDULED),
      disabled: !(
        [VisitStatus.COMPLETED, VisitStatus.NO_SHOW].includes(visitStatus) &&
        hasRole(user, [PROVIDER])
      ),
    },
    {
      component: "Mark as confirmed",
      onClick: () => handleChangeVisitStatus(VisitStatus.CONFIRMED),
      disabled: !(
        visitStatus === VisitStatus.SCHEDULED &&
        hasRole(user, [PROVIDER, FRONT_DESK])
      ),
    },
    {
      component: "Mark as no-show",
      onClick: markVisitAsNoShow,
      disabled: !(isEditable && hasRole(user, [PROVIDER])),
      divider: true,
    },
    ...(hasGfesFilled && !newGfeFlowV1
      ? [sendGfeToMdItem, selfApproveGfeItem, selfDeclineGfeItem]
      : []),
    ...(newGfeFlowV1 ? [startReviewItem] : []),
    ...(chartsOverdueV1Enabled
      ? chartDropdownItems
      : [{ ...sendChartToMdItem, divider: true }]),
    {
      component: "Refer to prescriber",
      onClick: () =>
        push(`/${medspa}/scripts/new?clientId=${clientId}&visitId=${visitId}`),
      disabled: !(isEligibleToRequestScripts && hasRole(user, [PROVIDER])),
    },
    {
      component: "Order Empower Patient Specific Script",
      onClick: () =>
        push(
          `/${medspa}/weight-loss-order/empower?client=${clientId}&visit=${visitId}`
        ),
      disabled: !(
        eligibleWeightLossPharmacies["empower"] && hasRole(user, [PROVIDER])
      ),
    },
    {
      component: "Order Strive Patient Specific",
      onClick: () =>
        push(
          `/${medspa}/weight-loss-order/strive-503a?client=${clientId}&visit=${visitId}`
        ),
      disabled: !(
        eligibleWeightLossPharmacies["strive503A"] && hasRole(user, [PROVIDER])
      ),
    },
    {
      component: "Order Strive Patient Specific",
      onClick: () =>
        push(
          `/${medspa}/weight-loss-order/strive-503b?client=${clientId}&visit=${visitId}`
        ),
      disabled: !(
        eligibleWeightLossPharmacies["strive503B"] && hasRole(user, [PROVIDER])
      ),
    },
    {
      component: "Edit appointment",
      onClick: () => push(`/${medspa}/visits/${visitId}/edit`),
      disabled: !isEditable,
    },
    // This is a default one, it could be either "Cancel appointment" or "Late cancel Appointment"
    {
      component: getCancelAppointmentLabel(),
      onClick: () => cancelAppointment({ skipFeeChargeAttempt: false }),
      disabled: !isEditable,
    },
    // This one appears only in pair with "Late cancel appointment" as an option for providers to mark as Cancelled while in late cancellation window
    {
      component: "Cancel appointment",
      onClick: () => cancelAppointment({ skipFeeChargeAttempt: true }),
      disabled: !isEditable || !isLateCancellation,
    },
    {
      component: (
        <Box display="flex" alignItems="center" gap={1}>
          <Typography>Charge late cancellation fee</Typography>
          {!hasCardsOnFile && (
            <Tooltip title="Client has no card on file">
              <IconButton>
                <WarningCircleIcon color={ERROR_PRIMARY} size="24px" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
      onClick: chargeLateCancellationFee,
      disabled: !(
        visitStatus === VisitStatus.CANCELLED_LATE &&
        hasRole(user, [PROVIDER]) &&
        collectCardOnBooking &&
        !isAlreadyInPaymentFlow
      ),
    },
    ...(adverseReactionsV1Enabled
      ? [
          {
            component: "Report Adverse Reaction",
            onClick: () =>
              push(`/${medspa}/visits/${visitId}/adverse-reactions/new`),
            disabled: !(
              hasRole(user, [PROVIDER]) &&
              ![
                VisitStatus.CANCELLED_LATE,
                VisitStatus.CANCELLED,
                VisitStatus.NO_SHOW,
              ].includes(visitStatus)
            ),
          },
        ]
      : []),
  ];

  const menuItems = items.filter((item) => !item.disabled);

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <DropdownMenu
        type={null}
        menuItems={menuItems}
        disabled={menuItems.length < 1}
        buttonVariant="icon"
        icon={<MenuHorizontalIcon color={PRIMARY} />}
      />
      {dialogComponent}
    </Box>
  );
}

export default VisitDropdown;
